import React from 'react';
import './Title.css';

const title = ({ setIsHovered }) => {
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div className='spacing-large title-container'>
      <div className='title-main'>
        <h2>zach dive</h2>
        <p className='big'>creative technologist</p>
      </div>
      <div >
      <h2>work</h2>
<p className='spacing-medium'>
building <a 
    href="https://www.ycombinator.com/" 
    target="_blank" 
    rel="noopener noreferrer"
    className="links"
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}
  > @yc.</a> 
  
  <br/>previously <a 
    href="https://www.adept.ai/" 
    target="_blank" 
    rel="noopener noreferrer"
    className="links"
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}
  >@adept ai labs.</a>
</p>
      </div>
    </div>
  );
}

export default title;